angular.module('app').component('paymentButton', {

    bindings: {
        departments: '<'
    },

    templateUrl: "/static/templates/components/payment-button.html",

    controller: function () {

        this.status = {
            isopen: false
        };

        this.toggleDropdown = function($event) {
            $event.preventDefault();
            $event.stopPropagation();
            this.status.isopen = !this.status.isopen;
        };
    }

});